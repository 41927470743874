<template>
  <CCard>
    <CCardHeader @click="cardCollapse = !cardCollapse">
      <h5 class="card-title">
        {{ messages.products.oil.toUpperCase() }},
        {{ messages.products.tyres.toUpperCase() }},
        {{ messages.products.paintAndCons.toUpperCase() }}
        {{ messages.common.and.toUpperCase() }}
        {{ messages.products.accessories.toUpperCase() }}
      </h5>
    </CCardHeader>
    <CCollapse :show="cardCollapse">
      <CCardBody>
        <CRow>
          <CCol xl="12">
            <p class="intro-text">{{ messages.home.curMonthComplLevel }}</p>
          </CCol>
        </CRow>
        <CRow class="flex--align-items-baseline">
          <CCol xl="3"
            ><p class="product-title">
              {{ messages.products.oil.toUpperCase() }}
            </p></CCol
          >
          <CCol xl="9" class="h-padding-xtra">
            <div class="text-center">
              <Loader
                v-if="showLoader"
                width="100px"
                height="100px"
                fill-color="#c2c2c2"
                cssClass="fadeIn anim-speed--1s"
              />
            </div>
            <RankingProgress
              v-if="!showLoader"
              code="T05"
              :value="completion.T05.value"
              :rawValue="completion.T05.rawValue"
              :concept="completion.T05.concept"
              :criterion="completion.T05.criterion"
              :progressBarValues="completion.T05.progressBarValues"
              class="fadeIn anim-speed--3s"
            />
          </CCol>
        </CRow>
        <CRow class="flex--align-items-baseline">
          <CCol xl="3"
            ><p class="product-title">
              {{ messages.products.tyres.toUpperCase() }}
            </p></CCol
          >
          <CCol xl="9" class="h-padding-xtra">
            <div class="text-center">
              <Loader
                v-if="showLoader"
                width="100px"
                height="100px"
                fill-color="#c2c2c2"
                cssClass="fadeIn anim-speed--1s"
              />
            </div>
            <RankingProgress
              v-if="!showLoader"
              code="T03"
              :value="completion.T03.value"
              :rawValue="completion.T03.rawValue"
              :concept="completion.T03.concept"
              :criterion="completion.T03.criterion"
              :progressBarValues="completion.T03.progressBarValues"
              class="fadeIn anim-speed--3s"
            />
          </CCol>
        </CRow>
        <CRow class="flex--align-items-baseline">
          <CCol xl="3"
            ><p class="product-title">
              {{ messages.products.paintAndCons.toUpperCase() }}
            </p></CCol
          >
          <CCol xl="9" class="h-padding-xtra">
            <div class="text-center">
              <Loader
                v-if="showLoader"
                width="100px"
                height="100px"
                fill-color="#c2c2c2"
                cssClass="fadeIn anim-speed--1s"
              />
            </div>
            <RankingProgress
              v-if="!showLoader"
              code="BPC"
              :value="completion.BPC.value"
              :rawValue="completion.BPC.rawValue"
              :concept="completion.BPC.concept"
              :criterion="completion.BPC.criterion"
              :progressBarValues="completion.BPC.progressBarValues"
              class="fadeIn anim-speed--3s"
            />
          </CCol>
        </CRow>
        <CRow class="flex--align-items-baseline">
          <CCol xl="3"
            ><p class="product-title">
              {{ messages.products.accessories.toUpperCase() }}
            </p></CCol
          >
          <CCol xl="9" class="h-padding-xtra">
            <div class="text-center">
              <Loader
                v-if="showLoader"
                width="100px"
                height="100px"
                fill-color="#c2c2c2"
                cssClass="fadeIn anim-speed--1s"
              />
            </div>
            <RankingProgress
              v-if="!showLoader"
              code="ACC"
              :value="completion.ACC.value"
              :rawValue="completion.ACC.rawValue"
              :concept="completion.ACC.concept"
              :criterion="completion.ACC.criterion"
              :progressBarValues="completion.ACC.progressBarValues"
              class="fadeIn anim-speed--3s"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol xl="12">
            <CButton color="primary" :to="{ name: 'Cuadro de mando' }">
              {{ messages.home.details.toUpperCase() }}
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCollapse>
  </CCard>
</template>

<script>
import Api from "@/services/Api";
import formatNum from "@/utils/formatNum";
import defaultAreaParam from "@/helpers/defaultAreaParam";
import currentFY from "@/services/CurrentFY";

const RankingProgress = () =>
  import(
    /* webpackChunkName: "ranking-progress-bar" */ "@/components/molecules/RankingProgress"
  );
const Loader = () =>
  import(/* webpackChunkName: "loader" */ "@/components/atoms/Loader");

export default {
  name: "CompletionCard",
  components: {
    RankingProgress,
    Loader,
  },
  data() {
    return {
      user: null,
      lang: null,
      messages: null,
      cardCollapse: true,
      showLoader: true,
      completion: {
        T03: {
          value: null,
          concept: "€/UIO",
          position: 0,
          progressBarValues: {
            max: 15,
            first: 0,
            second: 0,
            third: 0,
          },
        },
        T05: {
          value: null,
          concept: "€/UIO",
          position: 0,
          progressBarValues: {
            max: 15,
            first: null,
            second: null,
            third: null,
          },
        },
        BPC: {
          value: null,
          concept: "€/UIO",
          position: 0,
          progressBarValues: {
            max: 15,
            first: null,
            second: null,
            third: null,
          },
        },
        ACC: {
          value: null,
          concept: null,
          position: 0,
          progressBarValues: {
            max: 150,
            first: null,
            second: null,
            third: null,
          },
        },
      },
    };
  },
  computed: {
    implicitParams() {
      return defaultAreaParam(this.user);
    },
  },
  methods: {
    makeApiQueries() {
      console.log("this.implicitParams", this.implicitParams);
      currentFY().then((fy) => {
        const areaParam = {};
        areaParam[this.implicitParams.key] = this.implicitParams.value;
        const fyParam = { fiscalYear: fy };
        const monthParam = { month: new Date().getMonth() + 1 };
        const percentProducts = { productCodes: ["ACC"] };
        const averageProducts = { productCodes: ["T03", "T05", "BPC"] };
        const allProducts = {
          productCodes: [
            ...percentProducts.productCodes,
            ...averageProducts.productCodes,
          ],
        };
        const paramsPercent = {
          ...areaParam,
          ...fyParam,
          ...monthParam,
          cumulative: 0,
          ...percentProducts,
        };

        const paramsAverage = {
          ...areaParam,
          ...fyParam,
          ...monthParam,
          cumulative: 0,
          ...averageProducts,
        };

        const paramsCriteria = {
          ...fyParam,
          ...monthParam,
          languageId: this.user.languageId,
          ...allProducts,
        };

        const queries = [
          this.getProductPercentData(paramsPercent),
          this.getProductAverageData(paramsAverage),
          this.getProductsCompletionCriterion(paramsCriteria),
        ];

        Promise.all(queries)
          .then((results) => {
            console.log(">>>", results);
            const percentValues = results[0],
              averageValues = results[1],
              completionCriteria = results[2];
            this.processCompletionValues(percentValues, "percentageValue");
            this.processCompletionValues(averageValues, "average");
            this.processCompletionCriteria(completionCriteria);
            this.showLoader = false;
          })
          .catch((e) => console.log("ERROR", e));
      });
    },
    async getProductPercentData(params) {
      const results = await Api.sendPost("dashboard/completion", params);
      console.log("RESULTS", results);
      return results.data.data;
    },
    async getProductAverageData(params) {
      const results = await Api.sendPost("dashboard/productAverage", params);
      console.log("RESULTS AVERAGE", results);
      return results.data.data;
    },
    async getProductsCompletionCriterion(params) {
      const results = await Api.sendPost(
        "dashboard/completionCriterion",
        params
      );
      return results.data.data;
    },
    processCompletionValues(values, type) {
      values.map((elem) => {
        const percent = type === "percentageValue" ? "%" : "";
        this.completion[elem.productCode].value = `${formatNum.get(
          elem[type],
          2
        )}${percent}`;
        this.completion[elem.productCode].rawValue = elem[type];
        if (type === "percentageValue")
          this.completion[elem.productCode].concept =
            this.messages.ranking.completionAbbr;
      });
    },
    // Refactor this function is reused in @/views/ControlPanel.vue
    processCompletionCriteria(criteria) {
      criteria.map((product) => {
        const obj = this.completion[product.productCode];
        const min = Math.round(product.minValue);
        const max = Math.round(product.maxValue);
        const total = obj.progressBarValues.max;
        obj.criterion = {
          min: Math.round(product.minValue),
          max: Math.round(product.maxValue),
        };
        obj.progressBarValues.first = Math.round(min);
        obj.progressBarValues.second = Math.round(max - min);
        obj.progressBarValues.third = Math.round(total - max);
        let positions, zoneLimits;
        if (product.productCode !== "BV" && product.productCode !== "ACC") {
          zoneLimits = {
            first: Math.round(min),
            second: Math.round(max - min),
            third: Math.round(total - max),
          };
          positions = {
            positionMin: min / (total / 100),
            positionMax: max / (total / 100),
          };
        } else {
          const fakeMin = min - min * 0.1;
          const fakeMax = max + max * 0.1 - fakeMin;
          zoneLimits = {
            first: Math.round(fakeMin),
            second: Math.round(fakeMax),
            third: Math.round(total - (fakeMin + fakeMax)),
          };
          positions = {
            positionMin: fakeMin / (total / 100),
            positionMax: (max + max * 0.1) / (total / 100),
          };
        }

        obj.progressBarValues = {
          max: total,
          ...zoneLimits,
          legends: {
            min: min,
            max: max,
            total: total,
            ...positions,
          },
        };
      });
    },
  },
  created() {
    this.user = this.$store.getters.getCurrentUser;
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
    this.makeApiQueries();
  },
};
</script>
<style lang="scss" scoped>
.h-padding-xtra {
  padding-left: 3rem;
  padding-right: 3rem;
}

.intro-text {
  color: #8a93a2;
  margin: -1em 0 0.5em;
  font-size: 0.8rem;
}

.product-title {
  margin: 0;
  word-wrap: normal;
}
</style>
